import React, { useState } from 'react'
import UserPool from '../../Auth/UserPool';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';

function Login() {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [resetCode, setResetCode] = useState("");

    const handleConfirmPasswordReset = () => {
        const user = new CognitoUser({
            Username: email,
            Pool: UserPool
        });

        user.confirmPassword(resetCode, newPassword, {
            onSuccess: () => {
                console.log("New Password confirmed.")
            },
            onFailure: err => {
                console.log("Error confirming password: " + err)
            }
        })

    }

    const onSubmit = (event) => {
        event.preventDefault();

        const user = new CognitoUser({
            Username: email,
            Pool: UserPool
        });

        const authDetails = new AuthenticationDetails({
            Username: email,
            Password: password
        });

        user.authenticateUser(authDetails, {
            onSuccess: (data) => {
                console.log("onSuccess: ", data);
            },
            onFailure: (err) => {
                console.error("onFailure: ", err);
            },
            newPasswordRequired: (data) => {
                console.log("newPasswordRequired: ", data);

                user.completeNewPasswordChallenge(newPassword, null, {
                    onSuccess: (data) => {
                        console.log("CompleteNewPasswordChallenge success: " + data);
                    },
                    onFailure: (err) => {
                        console.error("completeNewPasswordChallenge error: ", err);
                    },
                })
            }
        })
    }


    return (
        <div>
            <form onSubmit={onSubmit}>
                <label htlmFor="email">Email</label>
                <input value={email} onChange={(event) => setEmail(event.target.value)}></input>
                <label htlmFor="password">Password</label>
                <input value={password} onChange={(event) => setPassword(event.target.value)}></input>
                <label htlmFor="new-password">New Password</label>
                <input value={newPassword} onChange={(event) => setNewPassword(event.target.value)}></input>
                <label htlmFor="Reset Code">Reset Code</label>
                <input value={resetCode} onChange={(event) => setResetCode(event.target.value)}></input>
                <button type="submit">Login</button>
            </form>
            <button onClick={handleConfirmPasswordReset}>Reset</button>
        </div>
    )
}

export default Login