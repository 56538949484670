import Login from "../../Components/Login";

const Home = () => {
    return (
    <>
        <h1>Home</h1>
        <Login />
    </>
    );
};

export default Home;