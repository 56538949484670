import React from 'react'
import './styles.css';


function SubmissionModal({isOpen, starRating, onClose}) {

    const handleClose = () => {
        onClose()
    }

    const onGoogleClicked = () => {
        window.location.replace('https://www.google.com/search?q=oculus+eye&oq=oculus+eye&gs_lcrp=EgZjaHJvbWUqDggAEEUYJxg7GIAEGIoFMg4IABBFGCcYOxiABBiKBTIGCAEQRRg8MgYIAhBFGDwyBggDEEUYPDIHCAQQABiABDIHCAUQABiABDIHCAYQABiABDINCAcQLhivARjHARiABDIHCAgQABiABDIHCAkQABiABNIBCDI3NjNqMGo3qAIasAIB4gMEGAEgXw&hl=en-US&sourceid=chrome-mobile&ie=UTF-8&dlnr=1&sei=axEFZu6hFPfA0PEPmMOAsAo#dlnr=1&lkt=LocalPoiReviews&lpg=cid:CgIgAQ%3D%3D&trex=m_t:lcl_akp,rc_f:rln,rc_ludocids:7887657053641298324,ru_gwp:0%252C7,ru_lqi:CgpvY3VsdXMgZXllSKjN6M-fuYCACFoUEAAQARgAGAEiCm9jdWx1cyBleWWSARRvcGh0aGFsbW9sb2d5X2NsaW5pY5oBI0NoWkRTVWhOTUc5blMwVkpRMEZuU1VSU04xQklUbGRuRUFF,trex_id:UAABYc')
    }

    return (
        <div className={`modal-bg ${isOpen ? "fadeIn" : "fadeOut"}`} onClick={handleClose}>
            <div className="modal-wrapper">
                <div className="modal-title">Thank you for your feedback!</div>
                { 
                    starRating == 5 ?
                    <>
                        <div className="modal-subtitle">We would really appreciate it if you left us a google review!</div>
                        <button className="google-image-wrapper" onClick={onGoogleClicked}>
                            <img className="google-image" src="./GoogleReviewImg.png" />
                        </button>
                    </>:
                    <>
                        <div className="modal-subtitle">We appreciate your input and will use it to continuously improve our service</div>
                        <div className="modal-redirect">Redirecting to home page...</div>
                    </>
                }
            </div>
        </div>
    )
}

export default SubmissionModal