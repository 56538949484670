import './styles.css';
import React, {useState, useEffect} from 'react';
import SubmissionModal from '../../Components/Modals/SubmissionModal';
import ReactStars from '../../Components/Stars/react-stars';
import useWindowDimensions from '../../Hooks/WindowDimensions';
import axios from 'axios';
import Select from 'react-dropdown-select';

const MAX_CHAR_COUNT = 300;

function ReviewPage() {
    const {height, width} = useWindowDimensions();
    const [starSize, setStarSize] = useState(60);
    const [charCountRemaining, setCharCountRemaining] = useState(MAX_CHAR_COUNT);
    const [reviewMessage, setReviewMessage] = useState('');
    const [starRating, setStarRating] = useState(5);
    const [currentEmployee, setCurrentEmployee] = useState('Dr.');

    const [open, setOpen] = React.useState(false);
    const [timeToRedirect, setTimeToRedirect] = React.useState(3);
    const timer = React.useRef(null);

    const reviewTextChanged = (newReviewText) => {
        setCharCountRemaining(MAX_CHAR_COUNT - newReviewText.target.value.length);
        setReviewMessage(newReviewText.target.value);
    };

    const [selectedReviewee, setSelectedReviewee] = useState({});

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setScreenWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    const isMobile = screenWidth <= 768;

    const options = [
        {
            id: 1,
            name: 'Dr. Michael Butler',
            image: './MichaelProfilePic.jpg',
        },
        {
            id: 2,
            name: 'Dr. Andrea Butler',
            image: './AndreaProfilePic.jpg',
        },
        {
            id: 3,
            name: 'Dr. Susan Wallace',
            image: './ComingSoon.jpg',
        },
        {
            id: 4,
            name: 'Dr. David Butler',
            image: './DavidProfilePic.png',
        },
        {
            id: 0,
            name: 'Clinic Review',
            image: './EyeClinic.png',
        },
    ];
    const defaultOption = [
        {
            id: 1,
            name: 'Dr. Michael Butler',
            image: './MichaelProfilePic.jpg',
        },
    ];
    useEffect(() => {
        function handleResize() {
            // Update the state or perform any other actions when the
            // browser is resized
        }
        setStarSize(width * 0.1);
        setSelectedReviewee(options[0]);
        // Attach the event listener to the window object
        window.addEventListener('resize', handleResize);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [width]);

    const ratingChanged = (newRating) => {
        console.log(newRating);
        setStarRating(newRating);
    };

    const homeRedirect = () => {
        window.location.replace('https://oculuseye.ca/');
    };

    const handleOpen = () => {
        setOpen(true);
        if (starRating != 5) {
            GoToHomePage();
        }
        console.log('Sending post...');
        const header = {
            'Content-Type': 'application/json',
        };
        axios
            .post(
                'https://xr52ingpv8.execute-api.us-west-2.amazonaws.com/test/createReview',
                JSON.stringify({
                    employee: currentEmployee,
                    rating: starRating,
                    message: reviewMessage,
                }),
                {headers: header}
            )
            .then(function (res) {
                console.log(res.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const handleClose = () => {
        setOpen(false);
        if (timer.current) {
            clearTimeout(timer.current);
        }
    };

    const GoToHomePage = () => {
        timer.current = setTimeout(() => {
            window.location.replace('https://oculuseye.ca/');
        }, 5000);
    };

    const onSelect = (option) => {
        console.log(option);
        setSelectedReviewee(option[0]);
    };

    const newApp = () => {
        return (
            //---------------------------------------------------------------------------------------------------------------------------
            // Main app
            //---------------------------------------------------------------------------------------------------------------------------
            <div className='App'>
                <SubmissionModal isOpen={open} starRating={starRating} onClose={handleClose} />
                <div className='wrapper'>
                    <div className='hero'>
                        <img src='./OculusEyeLogo.png' alt='' />
                    </div>
                    <div className='main-wrapper'>
                        <div className='header'>
                            <div className='header__line'></div>
                            <div className='header__title'>How was your experience ?</div>
                            <div className='header__line'></div>
                        </div>
                        <div className='disclaimer'>Reviews are kept anonymous to preserve privacy.</div>
                        <div className='card'>
                            <div className='card__img'>
                                <img className='profile-pic' src={selectedReviewee.image} alt='' />
                            </div>
                            <div
                                className='card__title'
                                style={{fontSize: isMobile ? 'var(--fs-300)' : 'var(--fs-400)'}}>
                                Select Doctor:
                                <Select
                                    className='myClassName'
                                    style={{width: isMobile ? '175px' : '350px', fontWeight: '400'}}
                                    options={options}
                                    labelField='name'
                                    valueField='id'
                                    onChange={onSelect}
                                    values={defaultOption}
                                />
                            </div>
                            <div className='card__rating'>
                                <ReactStars
                                    count={5}
                                    onChange={ratingChanged}
                                    size={starSize}
                                    activeColor='#ffd700'
                                    isHalf={true}
                                    value={5}
                                />
                            </div>
                            <div className='card__review'>
                                <textarea
                                    className='review-input'
                                    placeholder={
                                        selectedReviewee.id == 0
                                            ? `Write a review of the clinic`
                                            : `Write a review of ${selectedReviewee.name}`
                                    }
                                    maxLength={MAX_CHAR_COUNT}
                                    onChange={reviewTextChanged}
                                    rows={4}
                                />
                            </div>
                            <div className='card__count'>
                                <div className='count-wrapper'>{charCountRemaining}</div>
                            </div>
                        </div>
                        <div className='submit-btn-wrapper'>
                            {/* <TransitionsModal SendToGoogle={}/> */}
                            <button className='submit-btn' onClick={handleOpen}>
                                SUBMIT REVIEW
                            </button>
                        </div>
                    </div>
                    <div className='footer'>
                        <div className='links-wrapper'>
                            <a className='link' href='https://oculuseye.ca/'>
                                Home
                            </a>
                            <a className='link' href='https://oculuseye.ca/referrals'>
                                Referrals
                            </a>
                            <a className='link' href='https://oculuseye.ca/contact-us'>
                                Contact Us
                            </a>
                        </div>
                        <div className='company-name'>Oculus Eye</div>
                        <div className='copyright'>Copyright © 2024 Oculus Eye - All Rights Reserved.</div>
                    </div>
                </div>
            </div>
        );
    };

    return newApp();
}

export default ReviewPage;

//
