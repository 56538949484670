import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../Pages/Home";
import Layout from "../Pages/Layout";
import Blogs from "../Pages/Blogs";
import ReviewPage from "../Pages/Review";

export default function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/dashboard" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="blogs" element={<Blogs />} />
                </Route>
                <Route path="/" element={<ReviewPage />} />
            </Routes>
        </BrowserRouter>
    );
}